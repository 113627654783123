
import React from 'react'

import {Box, Stack} from '@mui/material'

import { withAuth } from 'hocs'
import { renderRoutesByRole } from 'utils/roles.utils'
import { ReactiveDialog } from 'components/common'
import { Navigation } from 'components/layout'
import { appRoutes } from 'routes'
import { ROLES } from 'config/user.config'
import { useVersionStamp } from 'hooks/common'
import {Theme} from "@mui/material/styles";

const App = () => {
    useVersionStamp()

    // const { loading: configLoading } = useConfig()
    // const { user, loading, error } = useUser()

    // if (loading || configLoading) {
    //     return <Loader />
    // }
    //
    // if (error) {
    //     return <GlobalError />
    // }

    return (
        <Box sx={{
            backgroundColor: (theme: Theme) => theme.colors.background,
            display: 'flex',
            justifyContent: 'stretch',
            gap: '20px',
            height: '100vh',
            padding: '20px',
        }}>
            <ReactiveDialog />
            <Stack sx={{
                display: 'flex',
                flexDirection:'column',
                position: 'relative',
                width: '100%',
                height: '100%',
                gap: '20px',
            }}>
                <Navigation />
                {renderRoutesByRole(appRoutes, 1 as ROLES)}
            </Stack>
        </Box>
    )
}

export default withAuth(App)
