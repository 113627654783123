import React, { Suspense } from 'react'

import { Route as RouteComponent, Routes, Navigate } from 'react-router-dom'

import { ROLES } from 'config/user.config'
import {Box} from "@mui/material";

export const renderRoutesByRole = (routes: any[], role: ROLES) => {
    if (role) {
        const allowedRoutes = role
            ? routes.filter(route =>
                  route.restrict ? route.restrict?.includes(role) : true
              )
            : []

        return (
            <Suspense fallback={<Box />}>
                <Routes>
                    <RouteComponent
                        path="/"
                        element={<Navigate to="homepage" />}
                    />
                    {allowedRoutes.map(r => (
                        <RouteComponent
                            key={r.name}
                            path={r.path}
                            element={r.element}
                            index={r.index}
                        />
                    ))}
                </Routes>
            </Suspense>
        )
    }

    return null
}
