import React, { lazy } from 'react'

import { authPaths, authRoutes } from './auth.routes'
import { adminPaths, adminRoutes } from './admin.routes'

const NotFoundPage = lazy(() => import('components/pages/common/NotFoundPage'))

const NotFoundRoute = {
    path: '*',
    element: <NotFoundPage />,
    name: 'notFound',
}

const appRoutes = [
    // put here all the roles' routes
    ...adminRoutes,
    NotFoundRoute,
]

export { authPaths, authRoutes, appRoutes, adminPaths }
