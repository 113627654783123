import React, { lazy } from 'react'

const LoginPage = lazy(
    () =>
        import(
            /* webpackChunkName: "LoginPage" */ 'components/pages/auth/LoginPage'
        )
)

const ResetPasswordPage = lazy(
    () =>
        import(
            /* webpackChunkName: "ResetPasswordPage" */ 'components/pages/auth/ResetPasswordPage'
        )
)
const SignUpPage = lazy(
    () =>
        import(
            /* webpackChunkName: "SignUpPage" */ 'components/pages/auth/SignUpPage'
        )
)
const ForgotPasswordPage = lazy(
    () =>
        import(
            /* webpackChunkName: "ForgotPasswordPage" */ 'components/pages/auth/ForgotPasswordPage'
        )
)

const NotFoundPage = lazy(
    () =>
        import(
            /* webpackChunkName: "NotFoundPage" */ 'components/pages/common/NotFoundPage'
        )
)

const authPaths = {
    home: 'auth',
    login: 'login',
    signUp: 'sign-up',
    emailVerification: 'email-verification',
    forgotPassword: 'forgot-password',
    resetPassword: 'create-password',
}

const authRoutes = [
    {
        path: authPaths.login,
        element: <LoginPage />,
        name: 'login',
    },
    {
        path: authPaths.signUp,
        element: <SignUpPage />,
        name: 'signUp',
    },
    {
        path: authPaths.forgotPassword,
        element: <ForgotPasswordPage />,
        name: 'forgotPassword',
    },
    {
        path: authPaths.resetPassword,
        element: <ResetPasswordPage />,
        name: 'resetPassword',
    },
    {
        path: '*',
        element: <NotFoundPage />,
        name: 'notFound',
    },
]
export { authPaths, authRoutes }
