import { GridView, AccountBox } from '@mui/icons-material'

import { adminPaths } from 'routes'
import { ROLES } from 'config/user.config'

interface Link {
    title: string
    path: string
    icon: JSX.Element | null
    end?: boolean
}

interface LinksCollection {
    [key: string]: Link[]
}

export default function generateNavigationLinksByRole(role: ROLES) {
    const links: LinksCollection = {
        '1': [
            {
                title: 'Homepage',
                path: adminPaths.homepage,
                icon: <GridView />,
                end: true,
            },
            {
                title: 'Account',
                path: adminPaths.account,
                icon: <AccountBox />,
                end: true,
            },
        ],
    }

    return links[role]
}
