import React, { lazy } from 'react'

const AdminHomePage = lazy(
    () =>
        import(
            /* webpackChunkName: "AdminHomePage" */ 'components/pages/admin/AdminHomePage'
        )
)

const AdminAccountPage = lazy(
    () =>
        import(
            /* webpackChunkName: "AdminAccountPage" */ 'components/pages/admin/AdminAccountPage'
        )
)

const adminPaths = {
    homepage: 'homepage',
    account: 'account',
}

const adminRoutes = [
    {
        path: adminPaths.homepage,
        element: <AdminHomePage />,
        name: 'homepage',
    },
    {
        path: adminPaths.account,
        element: <AdminAccountPage />,
        name: 'account',
    },
]

export { adminPaths, adminRoutes }
