export enum ROLES {
    ADMIN = 1,
    USER = 2,
}

export const MAX_PHOTOS_LIMIT = 5
export const MAX_PHOTO_SIZE = 20971520 // 20MB
export const PHOTO_ACCEPT_EXTENSIONS = {
    'image/png': ['.png'],
    'image/jpg': ['.jpg'],
    'image/jpeg': ['.jpeg'],
}
