import { useMutation, FetchResult } from '@apollo/client'

import {
    EmailSignInDocument,
    EmailSignInMutation,
    MutationEmailSignInArgs,
    EmailSignUpDocument,
    EmailSignUpMutation,
    EmailSignupInput,
    SocialAuthDocument,
    SocialAuthMutation,
    MutationSocialAuthArgs,
    ResetPasswordDocument,
    ResetPasswordMutation,
    ResetPasswordInput,
    ResetPasswordConfirmDocument,
    ResetPasswordConfirmMutation,
    ResetPasswordConfirmInput,
} from 'api/generated'
import { client } from 'api'
import { TokenStorage } from 'services'

export const useAuth = () => {
    const [signInApi, { loading: loginIsProcessing }] =
        useMutation(EmailSignInDocument)

    const [signUpApi, { loading: registerIsProcessing }] =
        useMutation(EmailSignUpDocument)

    const [socialApi, { loading: socialAuthIsProcessing }] = useMutation(
        SocialAuthDocument,
        { errorPolicy: 'all', notifyOnNetworkStatusChange: true }
    )

    const [resetPasswordApi, { loading: resetPasswordLoading }] = useMutation(
        ResetPasswordDocument
    )

    const [resetPasswordConfirmApi, { loading: resetPasswordConfirmLoading }] =
        useMutation(ResetPasswordConfirmDocument)

    const login = (data: MutationEmailSignInArgs, offline = false) => {
        if (offline) {
            if (data.email === 'test@test.mail' && data.password === '1234') {
                TokenStorage.storeRefreshToken('test refresh token')
                TokenStorage.storeToken('test token')
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                window.location = window.location.origin
                return Promise.resolve({ success: true, errors: [] })
            }
            return Promise.reject(new Error('Error'))
        }
        return signInApi({ variables: data }).then(
            (response: FetchResult<EmailSignInMutation>) => {
                console.log(response);
                const result = response?.data?.emailSignIn
                if (result?.success) {
                    const { token, refreshToken } = result
                    TokenStorage.storeToken(token)
                    TokenStorage.storeRefreshToken(refreshToken)
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    window.location = window.location.origin
                    return client.resetStore().then(() => {
                        return result
                    })
                }
                return result
            }
        )
    }

    const register = (data: EmailSignupInput) => {
        return signUpApi({ variables: { input: data } }).then(
            (response: FetchResult<EmailSignUpMutation>) =>
                response?.data?.emailSignUp
        )
    }

    const socialSignIn = (data: MutationSocialAuthArgs) => {
        return socialApi({ variables: data }).then(
            (response: FetchResult<SocialAuthMutation>) => {
                const result = response?.data?.socialAuth

                if (result?.success) {
                    const { token, refreshToken } = result
                    if (token && refreshToken) {
                        TokenStorage.storeToken(token)
                        TokenStorage.storeRefreshToken(refreshToken)
                    }
                    return client.resetStore().then(() => {
                        return result
                    })
                }
                return result
            }
        )
    }

    const logout = () => {
        TokenStorage.clear()
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        window.location = window.location.origin
    }

    const resetPassword = (data: ResetPasswordInput) => {
        return resetPasswordApi({ variables: { input: data } }).then(
            (response: FetchResult<ResetPasswordMutation>) =>
                response?.data?.resetPassword
        )
    }

    const resetPasswordConfirm = (data: ResetPasswordConfirmInput) => {
        return resetPasswordConfirmApi({ variables: { input: data } }).then(
            (response: FetchResult<ResetPasswordConfirmMutation>) =>
                response?.data?.resetPasswordConfirm
        )
    }

    return {
        login,
        register,
        socialSignIn,
        logout,
        resetPassword,
        resetPasswordConfirm,
        isLoading:
            resetPasswordLoading ||
            resetPasswordConfirmLoading ||
            loginIsProcessing ||
            socialAuthIsProcessing ||
            registerIsProcessing,
    }
}
