
import React from 'react'

import { Stack, Typography } from '@mui/material'

export interface PageTitleProps {
    title?: string
    children?: JSX.Element
}

const PageTitle = ({ title, children }: PageTitleProps): JSX.Element => {

    return (
        <Stack sx={{
            flexGrow: 0,
            flexDirection: 'row',
            justifyContent: 'stretch',
            alignItems: 'center',
            gap: '16px',
        }}>
            <Typography variant="h4">
                {title}
            </Typography>
            <Stack sx={{
                flexGrow: 1,
            }}>{children}</Stack>
        </Stack>
    )
}
export default PageTitle
