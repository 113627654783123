
import { Button, DialogActions } from '@mui/material'

interface DialogButtonsProps {
    onSubmit: () => void
    onCancel: () => void
    submitTitle?: string
    cancelTitle?: string
    submitButtonColor?: 'primary' | 'secondary'
    removePaddings?: boolean
}
const DialogButtons = ({
    onSubmit,
    onCancel,
    removePaddings = true,
    submitTitle = 'Done',
    cancelTitle = 'Cancel',
    submitButtonColor = 'secondary',
}: DialogButtonsProps): JSX.Element => {
    return (
        <DialogActions sx={{
            justifyContent: 'flex-end',
            padding: '24px',
            ...(removePaddings && {
                paddingLeft: 0,
                paddingRight: 0,
                paddingBottom: 0,
            })
        }}>
            <Button color={submitButtonColor} size="small" onClick={onSubmit}>
                {submitTitle}
            </Button>
            <Button variant="outlined" size="small" onClick={onCancel}>
                {cancelTitle}
            </Button>
        </DialogActions>
    )
}
export default DialogButtons
