import React from 'react'

import {Box} from '@mui/material'
import {AutoStories, Logout} from '@mui/icons-material'
import {Theme} from "@mui/material/styles";

import generateNavigationLinksByRole from './index.links'
import {ROLES} from 'config/user.config'
import {useAuth} from '../../../hooks/auth'
import { RouterNavLink } from "components/common";

const NavLinkStyles = {
    display: 'flex',
    alignItems: 'center',
    padding: '8px 16px',
    gap: '15px',
    fontSize: '18px',
    fontWeight: '500',
    background: (theme: Theme) => theme.colors.paperColor,
    textDecoration: 'none',
    cursor: 'pointer',
    '&:visited': {
        color: '#000',
    },
}

const Navigation: React.FC = () => {

    const {logout} = useAuth()

    return (
        <Box sx={{
            padding: '10px',
            background: (theme: Theme) => theme.colors.paperColor,
            minWidth: '320px',
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'row',
        }}>
            <Box sx={{
                display: 'flex',
                gap: '15px',
                flexDirection: 'row',
            }}>
                {generateNavigationLinksByRole(ROLES.ADMIN).map(link => {
                    return (
                        <RouterNavLink
                            end={link.end}
                            key={link.path}
                            to={link.path}
                            sx={{
                                ...NavLinkStyles,
                                '&.active': {
                                    color: (theme: Theme) => theme.colors.primary,
                                    backgroundColor: (theme: Theme) => theme.colors.background,
                                }
                            }}
                            className={({isActive}) => isActive ? 'active' : undefined}
                        >
                            {link.icon}
                            {link.title}
                        </RouterNavLink>
                    )
                })}
            </Box>
            <Box sx={{
                display: 'flex',
                gap: '15px',
                flexDirection: 'row',
            }}>
                <Box
                    sx={{
                        ...NavLinkStyles,
                        display: 'flex',
                        alignItems: 'center',
                        padding: '8px 16px',
                        gap: '15px',
                        fontWeight: '500',
                        background: (theme: Theme) => theme.colors.paperColor,
                        textDecoration: 'none',
                        color: '#ff4885',
                        cursor: 'pointer',
                        '& > a': {
                            color: '#ff4885',
                            textDecoration: 'none',
                        },
                        '&:visited': {
                            color: '#ff4885',
                        },
                        '&:hover': {
                            backgroundColor: '#ff4885',
                            color: '#fff',
                            '& > a': {
                                backgroundColor: '#ff4885',
                                color: '#fff',
                            },
                        },
                    }}
                >
                    <AutoStories/>
                    <a
                        href="https://storybook.js.org/"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Storybook
                    </a>
                </Box>
                <Box sx={NavLinkStyles} onClick={logout}>
                    <Logout/>
                </Box>
            </Box>
        </Box>
    )
}
export default Navigation
