
import React from 'react'

import {
    Dialog as MuiDialog,
    DialogContent,
    DialogTitle,
    IconButton,
    Stack,
} from '@mui/material'
import {CloseOutlined} from '@mui/icons-material'

import DialogButtons from './Buttons'

export interface DialogProps {
    title: string
    open?: boolean
    onClose: () => void
    onSubmit?: () => void
    children?: JSX.Element
}

const Dialog = (props: DialogProps): JSX.Element => {
    const {children, title, onSubmit, onClose, open = true} = props
    return (
        <MuiDialog
            maxWidth={false}
            open={open}
            onClose={onClose}
        >
            {title && (
                <DialogTitle sx={{
                    fontFamily: 'Roboto',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    marginBottom: '5px',
                }}>
                    <Stack sx={{
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>
                        <span>{title}</span>
                    </Stack>
                    <IconButton onClick={onClose}>
                        <CloseOutlined/>
                    </IconButton>
                </DialogTitle>
            )}
            <DialogContent sx={{
                display: 'flex',
                minWidth: '450px',
            }}>
                {children}
            </DialogContent>
            {onSubmit && (
                <DialogButtons
                    removePaddings={false}
                    onSubmit={onSubmit}
                    onCancel={onClose}
                />
            )}
        </MuiDialog>
    )
}

export default Dialog
