import React, { FC } from 'react'

import { Navigate } from 'react-router-dom'

import { TokenStorage } from 'services'

export const withAuth = (Component: FC): (() => JSX.Element) =>
    function WrappedComponent() {
        return TokenStorage.isAuthenticated() ? (
            <Component />
        ) : (
            <Navigate to={{ pathname: '/auth/login' }} />
        )
    }
