
import React, {ReactElement} from 'react'

import {Paper, Stack, Box, Typography, SxProps} from '@mui/material'

import {Loader} from '../index'
import {useDocumentTitle} from 'hooks/common'
import {Theme} from "@mui/material/styles";

interface AuthorizationLayoutProps {
    children: JSX.Element | JSX.Element[] | ReactElement | null
    title?: string
    sx?: SxProps<Theme>
    loading?: boolean
}

const AuthorizationLayout = ({
                                 children,
                                 title,
                                 sx,
                                 loading,
                             }: AuthorizationLayoutProps): JSX.Element => {

    useDocumentTitle(title)

    if (loading) {
        return <Loader/>
    }

    return (
        <Box
            sx={{
                paddingBottom: '90px',
                paddingTop: '90px',
                minHeight: '100vh',
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: (theme: Theme) => theme.colors.background,
                position: 'relative',
                ...sx
            }}
        >
            <Paper sx={(theme: Theme) => ({
                width: `min(100%, 426px)`,
                display: 'flex',
                position: 'relative',
                flexDirection: 'column',
                alignItems: 'stretch',
                padding: '60px 30px',
                borderRadius: '28px',
                [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
                    flexDirection: 'column',
                    margin: 0,
                },
            })}
                   elevation={0}>
                {title && (
                    <Typography sx={{
                        fontWeight: '400',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        textAlign: 'center',
                        marginBottom: '30px',
                    }} variant="medium24">
                        {title}
                    </Typography>
                )}
                <Stack>{children}</Stack>
            </Paper>
        </Box>
    )
}
export default AuthorizationLayout
