import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'

import './index.scss'

import React from 'react'

import { createRoot } from 'react-dom/client';
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { SnackbarProvider } from 'notistack'
import { ApolloProvider } from '@apollo/client'
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'

import { client } from 'api'
import { appTheme } from 'theme'
import App from 'components/app'
import Auth from 'components/auth'

declare global {
    interface Window {
        COMMIT_TAG: string
    }
}

const container = document.getElementById('root');
const root = createRoot(container as Element)

root.render( <ThemeProvider theme={appTheme}>
    <ApolloProvider client={client}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <SnackbarProvider
                maxSnack={3}
                autoHideDuration={5000}
                hideIconVariant
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <StyledEngineProvider injectFirst>
                    <CssBaseline />
                    <BrowserRouter>
                        <Routes>
                            <Route path="/*" element={<App />} />
                            <Route path="/auth/*" element={<Auth />} />
                        </Routes>
                    </BrowserRouter>
                </StyledEngineProvider>
            </SnackbarProvider>
        </LocalizationProvider>
    </ApolloProvider>
</ThemeProvider>)
